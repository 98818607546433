
import axios from 'axios';

const API_URL = 'https://backend.fimi.tech/api';

export const fetchCities = async () => {
  return axios.get(`${API_URL}/get-cities`);
};

export const fetchDistricts = async (provinceCode) => {
  return axios.get(`${API_URL}/get-districts/${provinceCode}`);
};

export const fetchWards = async (provinceCode) => {
  return axios.get(`${API_URL}/get-wards/${provinceCode}`);
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";
import { getLead } from "../services/apiService";

import LoadingOverlay from "../components/Loading";
const CheckData = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(180);
  const { userInfo } = useUserContext();
  const [loadings, setLoadings] = useState(false);
  const [leadStatus, setLeadStatus] = useState(null);
  const id = userInfo.id;
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);
  useEffect(() => {
    if (id) {

      const interval = setInterval(() => {
        getLead(id)
          .then((response) => {
            console.log("Lead data:", response.data);
            setLeadStatus(response.data.status); // Cập nhật trạng thái lead
            if (response.data.status === "Processing") {
              clearInterval(interval); // Dừng khi đạt trạng thái Processing
              setLoadings(false);
            }
          })
          .catch((error) => {
            console.error(error);
      
          });
      }, 3000); // Kiểm tra mỗi 3 giây

      return () => clearInterval(interval);
    }
  }, [id]);
  return (

      <div className="flex flex-col items-center p-5 bg-white h-screen">
              {loadings && <LoadingOverlay />}

        <h1 className="uppercase text-xl font-bold mt-6 text-green-500 text-center">
          Đang chờ phê duyệt từ ngân hàng
        </h1>
        <div className="w-[95%] h-[210px] bg-green-300 mt-8 rounded-2xl custom-shadow">
          <div className="flex flex-row m-3">
            <p className="text-base text-green-800 flex-wrap font-light ml-2 leading-6 w-[70%] mt-4 mr-2">
              Ngân hàng xử lí thông tin của bạn, quy trình xử lý thường diễn ra
              trong 3 phút. Vui lòng không đóng trình duyệt trong khoảng thời
              gian này. Còn
              <span className="text-green-500 font-bold"> {countdown}s</span>
            </p>
            <img
              src={require("../assets/icon/list.png")}
              alt="List Icon"
              className="mr-2 w-20 h-28 mt-7"
            />
          </div>
        </div>
        <div className="items-center justify-center mt-9 mb-14">

          <button
            onClick={() => navigate("/vpbank-creditcard/chooseProduct")} 
            className="bg-gray-300 rounded-lg w-64 h-10 items-center justify-center mb-5 transition-transform duration-300 hover:scale-105 hover:bg-gray-300"
          >
            <span className="text-gray-800 text-base font-semibold">Tiếp tục</span>
          </button>

        </div>
      </div>

  );
};

export default CheckData;

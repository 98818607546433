import React from 'react';

const Copyright = () => {
  return (
    <div className="h-14 bg-gradient-to-r from-green-600 to-green-500 flex flex-col justify-center items-center">
      <p className="text-white text-xs">@ 2022 VPBank - Bản quyền đã được bảo hộ</p>
      <p className="text-white text-xs">Chính sách bảo mật</p>
    </div>
  );
};

export default Copyright;

import React from "react";
import check from "../assets/icon/check.png"; 

const Header = ({ currentStep }) => {
  return (
    <div className="mb-1 flex flex-col ">
      <div className="flex items-center ml-2 ">
        <img src={check} className="mr-2 w-5 h-4" alt="check" />
        <span className="text-base font-semibold">Hạn mức lên đến 100 triệu</span>
      </div>

      <div className="flex items-center ml-2">
        <img src={check} className="mr-2 w-5 h-4" alt="check" />
        <span className="text-base font-semibold">Trả kết quả chỉ trong 5 phút</span>
      </div>

      <div>
      <h2 className="text-xl font-extrabold text-center mt-8 text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700">
  Đăng ký ngay, nhận thẻ liền tay
</h2>

      </div>
      
      <div className="flex flex-row items-center mt-5   justify-center">
        {[1, 2, 3, 4].map((step, index) => (
          <div key={step} className="relative mx-4">
            <div
              className={`w-6 h-6 rounded-full flex justify-center items-center ${
                step <= currentStep ? "bg-green-400" : "bg-gray-300"
              }`}
              style={{ zIndex: 1 }} 
            >
              <span className="text-white font-bold text-xs ">{step}</span>
            </div>

            {index < 3 && (
              <div
                className={`absolute h-1 w-6 ml-2 ${
                  step < currentStep ? "bg-green-400" : "bg-gray-300"
                }`}
                style={{
                  left: 20,
                  top: "50%",
                  zIndex: 0, 
                  transform: "translateY(-1.5px)", 
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;

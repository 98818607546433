import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";
import { fetchCities } from "../services/locationService";
import {
  getRelationships,
  getUpdateToLOS,
  Reference_info,
  updateLead,
  UploadDcument,
  uploadLiveness,
  uploadOCRBack,
  uploadOCRFront,
} from "../services/apiService";
import LoadingOverlay from "../components/Loading";
import Swal from "sweetalert2";
const Contact = () => {
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const { userInfo } = useUserContext();
  const [errors, setErrors] = useState({});
  const [loadings, setLoadings] = useState(false);
  const { productInfo, setProductInfo } = useUserContext();
  const id = localStorage.getItem("id");
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    relationships: "",
    security_question: "",
    security_answer: "",
    shipping_address: "",
    branch_city: "",
  });
  useEffect(() => {
    setLoadings(true);
    fetchCities()
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  useEffect(() => {
    setLoadings(true);

    getRelationships()
      .then((response) => {
        setRelationships(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  const provincesArray = provinces.map((city) => (
    <option key={city.id} value={city.code}>
      {city.name}
    </option>
  ));
  const handleSubmit = () => {
    const newErrors = {};
    if (!formData.phone) newErrors.phone = "Vui lòng nhập số điện thoại.";
    if (!formData.relationships) newErrors.relationships = "Vui lòng chọn mối quan hệ.";
    if (!formData.security_question) newErrors.security_question = "Vui lòng nhập câu hỏi bảo mật.";
    if (!formData.security_answer) newErrors.security_answer = "Vui lòng nhập câu trả lời.";
    if (!formData.shipping_address) newErrors.shipping_address = "Vui lòng nhập địa chỉ nhận.";
    if (!formData.branch_city) newErrors.branch_city = "Vui lòng chọn tỉnh/thành phố.";
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);
      const dataLead = [
        {
          reference1_fullname: formData.fullName,
          reference1_mobile_phone_number: formData.phone,
          reference1_relationship: formData.relationships,
          card_type: productInfo.szcode,
          security_answer: formData.security_answer,
          security_question: formData.security_question,
          reference_person: [
            {
              fullName: formData.fullName,
              phone: formData.phone,
              relationships: formData.relationships,
              shipping_address: formData.shipping_address,
              branch_city: formData.branch_city,
            },
          ],
        },
      ];
      const dataStatus = [
        {
          reference1_fullname: formData.fullName,
          reference1_mobile_phone_number: formData.phone,
          reference1_relationship: formData.relationships,
          card_type: productInfo.szcode,
          security_answer: formData.security_answer,
          security_question: formData.security_question,
          reference_person: [
            {
              fullName: formData.fullName,
              phone: formData.phone,
              relationships: formData.relationships,
              shipping_address: formData.shipping_address,
              branch_city: formData.branch_city,
            },
          ],
        },
      ];
      
      // Reference_info(id, dataStatus)
      // .then((response) => {
      //   if (response.data.success) {
      //     console.log("dathanhcong:",response.data)
      //   } else {
      //     console.error("Error saving starting info:", response.data.message);
      //   }
      // })
      // .catch((error) => console.error("Error saving starting info:", error));
      Reference_info(id, dataStatus)
        .then((response) => {
  
          return Promise.all([
            getUpdateToLOS(id)
          ]);
        })
        .then(([ocrFrontResponse, ocrBackResponse, livenessResponse, leadResponse]) => {
          return getUpdateToLOS(id);
        })
        .then(() => {
          Swal.fire({
          title: "Hoàn thành đăng ký",
          text: "Thẻ sẽ được gửi đến tay của bạn!",
          icon: "success",
        }).then(() => {
          navigate('/vpbank-creditcard/complete'); 
        });
      })
        .catch((error) => {
            console.error("Cập nhật thất bại", error);
            Swal.fire({
              title: "Cập nhật thất bại",
              text: "Trạng thái hồ sơ chưa được duyệt!",
              icon: "error",
            });
        })
        .finally(() => setLoadings(false));
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      {loadings && <LoadingOverlay />}

      <div className="flex items-center mt-2 ml-2">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-green-500 mr-6 w-[60%]">
          Thông tin người thân và địa chỉ phát thẻ
        </p>
        <img
          src={require("../assets/parent.png")}
          className="w-24 h-[100px]"
          alt="Parent"
        />
      </div>

      <div className="h-[2.5px] bg-green-400 w-full mt-8" />
      <div className="mt-8 w-full max-w-md bg-green-300 rounded-2xl p-4 custom-shadow">
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Họ và tên người thân cần liên hệ:
          </label>
          <input
            name="fullName"
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập họ và tên"
            type="text"
            value={formData.fullName}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Mối quan hệ:
          </label>
          <select
            name="relationships"
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            value={formData.relationships}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Chọn mối quan hệ
            </option>
            {relationships.map((relationship, key) => (
              <option value={relationship.code}>{relationship.name}</option>
            ))}
          </select>
          {errors.relationships && (
            <p className="text-red-500 text-xs mt-1">{errors.relationships}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Số điện thoại:
          </label>
          <input
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="h-10 rounded-md text-sm px-2 bg-white w-full"
            placeholder="Nhập số điện thoại"
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={10}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Câu hỏi bảo mật:
          </label>
          <input
            name="security_question"
            value={formData.security_question}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập câu hỏi"
            type="text"
          />
          {errors.security_question && (
            <p className="text-red-500 text-xs mt-1">
              {errors.security_question}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Câu trả lời:
          </label>
          <input
            name="security_answer"
            value={formData.security_answer}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập câu trả lời"
            type="text"
          />
          {errors.security_answer && (
            <p className="text-red-500 text-xs mt-1">
              {errors.security_answer}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Địa chỉ nhận:
          </label>
          <input
            name="shipping_address"
            value={formData.shipping_address}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập địa chỉ nhận"
            type="text"
          />
          {errors.shipping_address && (
            <p className="text-red-500 text-xs mt-1">
              {errors.shipping_address}
            </p>
          )}
        </div>
        <label className="block text-[13px] font-normal ml-2 mb-6 leading-[18px] text-green-700">
          Vui lòng lựa chọn địa điểm giao trong trường hợp thẻ không thể giao
          đến địa chỉ mong muốn:
        </label>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Tỉnh/Thành phố chi nhánh:
          </label>
          <select
            name="branch_city"
            value={formData.branch_city}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
          >
            <option value="" disabled selected>
              Chọn tỉnh/thành phố
            </option>
            {provincesArray}
          </select>
          {errors.branch_city && (
            <p className="text-red-500 text-xs mt-1">{errors.branch_city}</p>
          )}
        </div>
      </div>
      <div className="items-center justify-center mt-10 mb-10">
        <button
          onClick={handleSubmit}
          className="bg-green-500 rounded-lg w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500 "
        >
          <p className="text-white text-base font-semibold">Tiếp tục</p>
        </button>
      </div>
    </div>
  );
};

export default Contact;

import React, { useState, useRef, useEffect } from "react";
import { useUserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../components/Loading";
import { assignOtp, verifyOtp } from "../services/apiService";
import Swal from "sweetalert2";

const OTPScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);
  const [timeLeft, setTimeLeft] = useState(180);
  const { userInfo } = useUserContext();
  const [loadings, setLoadings] = useState(false);
  const [sessionId, setSessionId] = useState(null); 
  const [otpNumber, setOtpNumber] = useState(null); 
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchOtp = async () => {
      try {
        setLoadings(true);
        const response = await assignOtp({
          phone_number: userInfo.phone_number,
        });
        if (response.data.data.assignOTPResult) {     
          const otpString = response.data.data.assignOTPResult.toString();
          const otpArray = otpString.split("");
          setSessionId(response.data.data.sessionId);
          setOtpNumber(response.data.data.assignOTPResult);
          setOtp(otpArray);
    
        }
      } catch (error) {
        console.error("Error assigning OTP:", error);
      } finally {
        setLoadings(false);
      }
    };

    fetchOtp();
  }, [userInfo.phone_number]);
  const handleChange = (text, index) => {
    if (/^[0-9]$/.test(text) || text === "") {
      const newOtp = [...otp];
      newOtp[index] = text;
      setOtp(newOtp);

      if (text.length === 1 && index < otp.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };
   
  const handleVerifyOtp = async () => {
    const otp_number = otp.join("");
    try {
      setLoadings(true);
      const otpAsString = String(otp_number);
      const response = await verifyOtp({
        phone_number: userInfo.phone_number,
        otp_number:otpAsString,
        sessionId:sessionId
      
      });

      if (response.data.status === "success") {
        navigate("/vpbank-creditcard/checkData");
        Swal.fire({
          icon: 'success',
          title: 'Xác thực OTP thành công',
          text: 'Đang chuyển trang...',
          background: '#fff', 
          timer: 1600, 
        });
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("An error occurred during OTP verification.");
    } finally {
      setLoadings(false);
    }
  };
  const isComplete = otp.every((digit) => digit !== "");
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);
 
  return (
    <div className="flex flex-col mb-10  p-4 bg-white">
      <h1 className="text-xl font-extrabold text-center mt-2 text-transparent bg-clip-text bg-gradient-to-r from-green-700 to-green-500">
        Đăng ký ngay, nhận thẻ liền tay
      </h1>
      <div className="text-left ml-4 mt-2 w-1/2">
        <p className="text-sm font-normal">
          Vui lòng nhập mã sos OTP được gửi về số điện thoại
        </p>
      </div>
      <div className="flex flex-row  items-center justify-center mt-8 w-4/5 mx-auto gap-1">
        {otp.map((digit, index) => (
          <input
            key={index}
            className="h-12 w-12 bg-gray-200 rounded text-center font-bold text-lg"
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            ref={(ref) => (inputsRef.current[index] = ref)}
          />
        ))}
      </div>
      <p className="text-center text-sm text-gray-600 mt-6">
        Mã OTP sẽ hết hạn trong vòng{" "}
        <span className="text-green-500 font-bold">{timeLeft} giây</span>{" "}
      </p>

      {isComplete && (
        <div className="flex items-center justify-center mt-6 mb-10">
          <button
            className="bg-green-500 rounded-lg w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500"
            onClick={handleVerifyOtp}
          >
            <p className="text-white text-base font-semibold">Tiếp tục</p>
          </button>
        </div>
      )}

      {error && <p className="text-center text-red-500 mt-2">{error}</p>}
      <div className="ml-1 mt-4">
        <p className="text-left text-sm text-gray-900 mt-2">Lưu ý:</p>
        <div className="text-xs">
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Bạn chỉ nhập mã OTP tối đa 5 lần.
          </p>
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Phiên làm việc sẽ tạm ngưng 30 phút nếu gửi lại OTP quá 5 lần.
          </p>
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Bằng việc tiếp tục, tôi đồng ý để VPBank xử lý dữ liệu.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTPScreen;

import React from "react";
import logo from "../assets/logo/vpbank.svg"; 
const Header = () => {

  return (
    <div className="bg-white h-24 ">
      <img className=" mt-5 w-36 h-16 ml-8 " src={logo}  alt="logo" />
    </div>
  );
};

export default Header;

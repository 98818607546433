import React from "react";
import { useNavigate } from "react-router-dom";
const PageStart = () => {
  const navigate = useNavigate();
  return (
    <div className=" items-center p-5 bg-white h-[2800px]">
      <div className="flex items-center justify-center flex-col">
        <div className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-yellow-500 text-xl">
          MỞ THẺ KHÔNG CẦN
        </div>
        <div className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-yellow-500 text-4xl">
          BẢNG LƯƠNG
        </div>
      </div>

      <div className="relative flex items-center justify-center h-[330px] mt-14">
        <div className="absolute top-[25%] left-[34%] z-10 transform -translate-x-1/2 -translate-y-1/2">
          <img src={require("../assets/Group (1).png")} alt="Image 1" />
        </div>
        <div className="absolute top-[23%] right-[17%] z-10 transform translate-x-1/2 -translate-y-1/2">
          <img src={require("../assets/Group (2).png")} alt="Image 2" />
        </div>
        <div className="absolute top-[9%] w-full z-0">
          <img src={require("../assets/Group (3).png")} alt="Image 3" />
        </div>
        <div className="absolute top-[27%] w-full z-10">
          <img src={require("../assets/Group.png")} alt="Image 4" />
        </div>
      </div>
      <div class="flex items-center ml-3 mr-3">
        <div class=" items-center justify-center">
          <div class="font-extrabold text-xl">
            MỞ THẺ <span class="text-yellow-500">ONLINE</span> CHỈ VỚI{" "}
            <span class="text-yellow-500">CCCD GẮN CHIP</span>
          </div>
          <div class=" text-base">
            Không cần giấy tờ chứng minh thu nhập, chỉ với một lần đăng ký trong
            10 phút.
          </div>
        </div>
        <div className="">
          <img
            src={require("../assets/handchip.png")}
            alt="Image 4"
            className="w-96 h-auto"
          />
        </div>
      </div>
      <div className="relative">
        <img
          src={require("../assets/Rectangle 52.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-0 left-0 p-4 flex items-center space-x-4">
          <div>
            <img src={require("../assets/img_1.png")} alt="Image 4" />
          </div>
          <div className="flex flex-col items-center text-center w-[56%] mt-3">
            <div class="font-extrabold text-yellow-500">
              HẠN MỨC <span class="text-white">THẺ LÊN ĐẾN</span> 100 TRIỆU
            </div>
            <div class="text-xs text-white font-light">
              Chọn thẻ tín dụng VPBank theo nhu cầu chi tiêu cá nhân.
            </div>
            <div class="font-extrabold text-yellow-500">
              MIỄN PHÍ <span class="text-white">PHÍ THƯỜNG NIÊN</span>
            </div>
            <div class="text-xs text-white font-light">
              Cho năm đầu tiên và ưu đãi với những năm tiếp theo từng loại thẻ
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex mt-8">
          <div>
            <img src={require("../assets/image 3.png")} alt="Image 4" />
          </div>
          <div class="flex flex-col items-center text-center w-[50%] mt-10">
            <div class="font-extrabold text-yellow-500">
              HOÀN TIỀN <span class="text-black">CHI TIÊU LÊN ĐẾN</span>{" "}
              15%/THÁNG
            </div>
            <div class="text-xs text-black font-light">
              Chọn thẻ tín dụng VPBank theo nhu cầu chi tiêu cá nhân.
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-8">
        <img
          src={require("../assets/Rectangle 52.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-0 left-0 p-4 flex items-center space-x-4">
          <div>
            <img src={require("../assets/Group 62.png")} alt="Image 4" />
          </div>
          <div className="flex flex-col items-center text-center w-[70%] mt-3">
            <div class="font-extrabold text-white">
              ƯU ĐÃI <span class="text-yellow-500">GIẢM GIÁ</span> ĐỐI TÁC LÊN
              ĐẾN <span class="text-yellow-500">40%</span>
            </div>
            <div class="text-xs text-white font-light">
              Tại hơn 5,000 đối tác cửa hàng ăn uống, du lịch, mua sắm, giải
              trí, học tập
            </div>
            <div class="font-extrabold text-white">
              MUA SẮM <span class="text-yellow-500">TRƯỚC</span> TRẢ GÓP{" "}
              <span class="text-yellow-500">0%</span> SAU
            </div>
            <div class="text-xs text-white font-light">
              Chuyển đổi trả góp với kỳ hạn linh hoạt tới 48 tháng chỉ với 6
              bước đơn giản
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-8 ml-3 mr-3">
        <div class="flex flex-col items-center text-center w-[60%] mt-10">
          <div class="font-extrabold text-yellow-500">
            QUÀ TẶNG <span class="text-black">MỞ THẺ LÊN ĐẾN</span> 500.000 VNĐ
          </div>
          <div class="text-xs text-black font-light">
            Và nhiều phần quà tặng ưu đãi khác theo từng loại thẻ đang chờ bạn
          </div>
        </div>
        <div>
          <img src={require("../assets/img-2.png")} alt="Image 4" />
        </div>
      </div>
      <div className="relative mt-8 ">
        <img
          src={require("../assets/Rectangle 43.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-0 left-0 p-4 flex flex-col space-y-2 ">
          <div className="flex flex-col items-center text-center mt-2 ml-4 mr-4">
            <div className="font-extrabold text-white text-xl">
              CÓ THẺ <span className="text-yellow-500">DÙNG NGAY </span>
              CHỈ <span className="text-yellow-500">4 BƯỚC </span> ĐƠN GIẢN
            </div>
          </div>

          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 1:</div>
            <div className="font-light text-xs">Nhập thông tin đăng ký</div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 2:</div>
            <div className="font-light text-xs">
              Định danh điện tử với CCCD gắn chip
            </div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 3:</div>
            <div className="font-light text-xs">
              Lựa chọn thẻ phù hợp theo nhu cầu
            </div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 4:</div>
            <div className="font-light text-xs">
              Kích hoạt thẻ online sau khi phê duyệt
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-1 ml-3 mr-3">
        <div class="flex flex-col items-center text-center mt-10">
          <div class="font-extrabold text-black text-xl w-[70%]">
            CHỌN THẺ{" "}
            <span class="text-yellow-500">VPBANK JCB PHÙ HỢP NHẤT</span> VỚI BẠN
          </div>
          <div class="text-xs text-black font-medium mt-2 text-left">
            Trên thị trường ngân hàng Việt Nam hiện nay, các loại thẻ tín dụng
            ngày càng trở nên đa dạng và phong phú, đáp ứng nhu cầu sử dụng của
            người tiêu dùng từng ngày. Trong số đó, các loại thẻ VPBank JCB là
            một trong những sự lựa chọn phổ biến được nhiều người quan tâm. Tuy
            nhiên, trước sự đa dạng của các loại thẻ này, việc lựa chọn loại thẻ
            phù hợp nhất với nhu cầu và điều kiện của bản thân không hề dễ dàng.
            Bài viết này sẽ so sánh và đánh giá các loại thẻ VPBank JCB hiện
            nay, từ đó giúp bạn có cái nhìn tổng quan và đưa ra quyết định hợp
            lý.
          </div>
          <button class="mt-10 bg-gradient-to-r from-red-900 to-red-600 text-white text-sm font-bold py-2 px-4 rounded-[30px] w-[45%]">
            Xem thêm
          </button>
        </div>
      </div>
      <div className="relative mt-8 ">
        <img
          src={require("../assets/Group 63.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
      </div>
      <div class="flex justify-center items-center">
  <button
    onClick={() => navigate("/vpbank-creditcard")}
    className="mt-10 w-[90%] h-[50px] bg-gradient-to-r from-red-900 to-red-600 text-white rounded-[30px] text-lg font-bold shadow-lg hover:shadow-xl transition-all transform hover:scale-105"
  >
    Mở thẻ ngay
  </button>
</div>

    </div>
  );
};

export default PageStart;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import Select from "react-select";
import LoadingOverlay from "../components/Loading";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import {
  fetchCities,
  fetchDistricts,
  fetchWards,
} from "../services/locationService";
import {
  createLead,
  getCompany,
  getCreateToLOS,
  getEmployeeStatus,
  getJobPosition,
  Job_info,
} from "../services/apiService";
import { useUserContext } from "../UserContext";
import Swal from "sweetalert2";
const Step4Screen = () => {
  const navigate = useNavigate();
  const [currentStep] = useState(4);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [employeeStatuss, setEmployeeStatuss] = useState([]);
  const [companys, setCompanys] = useState([]);
  const [employerName, setEmployerName] = useState("");
  const [employerStatus, setEmployerStatus] = useState("");
  const [otherEmployerName, setOtherEmployerName] = useState("");
  const [detailedAddress, setDetailedAddress] = useState("");
  const [incomeAmount, setIncomeAmount] = useState("");
  const { userInfo, setUserInfo } = useUserContext();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [errors, setErrors] = useState({});
  const id = localStorage.getItem("id");
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({
    user_agent: "",
    country: "",
    city: "",
    type_of_device: "",
    operating_system: "",
    browser_name: "",
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const typeOfDevice = isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop";
    const operatingSystem = /Windows/i.test(userAgent)
      ? "Windows 10"
      : /Mac/i.test(userAgent)
      ? "macOS"
      : /Linux/i.test(userAgent)
      ? "Linux"
      : "Other OS";
    const browserName = /Chrome/i.test(userAgent)
      ? "Chrome"
      : /Firefox/i.test(userAgent)
      ? "Firefox"
      : /Safari/i.test(userAgent)
      ? "Safari"
      : "Other Browser";

    setDeviceInfo((prev) => ({
      ...prev,
      user_agent: userAgent,
      type_of_device: typeOfDevice,
      operating_system: operatingSystem,
      browser_name: browserName,
    }));
  }, []);

  const handleLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        setDeviceInfo((prev) => ({
          ...prev,
          country: data.countryName || "Unknown",
          city: data.locality || data.principalSubdivision || "Unknown",
        }));
      })
      .catch((error) => console.error("Error fetching location data:", error));
  };

  const handleLocationError = () => {
    console.warn("User denied geolocation request or it failed.");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      handleLocationSuccess,
      handleLocationError
    );
  }, []);

  const isEmployerStatusEnabled =
    employerStatus &&
    ["FT", "PT", "BO"].includes(
      employeeStatuss.find((status) => status.id === employerStatus.value)?.code
    );
  useEffect(() => {
    if (!isEmployerStatusEnabled) {
      setSelectedJobPosition("");
      setSelectedCompany("");
      setOtherEmployerName("");
      setProvince("");
      setDistrict("");
      setWard("");
      setDetailedAddress("");
    }
  }, [isEmployerStatusEnabled]);
  const handleFinalSubmit = () => {
    const newErrors = {};

    if (!employerStatus)
      newErrors.employerStatus = "Vui lòng chọn tình trạng công việc";
    if (isEmployerStatusEnabled) {
      if (!selectedJobPosition)
        newErrors.selectedJobPosition = "Vui lòng chọn vị trí công việc";
      if (!selectedCompany) newErrors.selectedCompany = "Vui lòng chọn công ty";
      if (isOtherCompany && !otherEmployerName)
        newErrors.otherEmployerName = "Vui lòng nhập tên công ty khác";
      if (!province) newErrors.province = "Vui lòng chọn tỉnh/thành phố";
      if (!district) newErrors.district = "Vui lòng chọn quận/huyện";
      if (!ward) newErrors.ward = "Vui lòng chọn xã/phường";
      if (!detailedAddress)
        newErrors.detailedAddress = "Vui lòng nhập địa chỉ chi tiết";
    }

    if (!incomeAmount)
      newErrors.incomeAmount = "Vui lòng nhập thu nhập hàng tháng";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const dataToSubmit = {
      ...userInfo,
      employer_name: selectedCompany.value,
      employer_status: employerStatus.value,
      job_position: selectedJobPosition.value,
      other_employer_name: otherEmployerName,
      employer_province: province,
      employer_district: district,
      employer_ward: ward,
      employer_address: detailedAddress,
      income_amount: incomeAmount,
      device: deviceInfo,
    };
    const dataStatus = {
      employer_name: selectedCompany.value,
      employer_status: employerStatus.value,
      job_position: selectedJobPosition.value,
      other_employer_name: otherEmployerName,
      employer_province: province,
      employer_district: district,
      employer_ward: ward,
      employer_address: detailedAddress,
      income_amount: incomeAmount,
      device: deviceInfo,
    };
    setUserInfo(dataToSubmit);

    setLoadings(true);
    Job_info(id, dataStatus)
    .then((response) => {
      console.log("Job_info response:", response);
  
    
      return getCreateToLOS(response.data.data.id);
    })
    .then((losResponse) => {
      console.log("getCreateToLOS response:", losResponse);
      console.log("Data submitted successfully");
      navigate("/vpbank-creditcard/OTPScreen");
    })
    .catch((error) => {
      console.error("Error posting data:", error);
      const errorMessage =
        JSON.stringify(error.response?.data, null, 2) || "Đã xảy ra lỗi";

      Swal.fire({
        icon: "error",
        title: "Lỗi từ hệ thống",
        text: errorMessage,
        confirmButtonText: "Đồng ý",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        background: "#fff",
        timer: 5000,
      });
    })
    .finally(() => setLoadings(false));
};

  useEffect(() => {
    setLoadings(true);
    fetchCities()
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getCompany()
      .then((response) => {
        setCompanys(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getJobPosition()
      .then((response) => {
        setJobPositions(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);

    getEmployeeStatus()
      .then((response) => {
        setEmployeeStatuss(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);
    setLoadings(true);

    const provinceObject = provinces.find(
      (prov) => prov.code === selectedProvince
    );

    if (provinceObject) {
      fetchDistricts(selectedProvince)
        .then((response) => {
          setDistricts(response.data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoadings(false));
    }

    setWards([]);
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setLoadings(true);

    fetchWards(selectedDistrict)
      .then((response) => {
        setWards(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  };
  const handleCompanyChange = (selected) => {
    setLoadings(true);
    setSelectedCompany(selected);
    setIsOtherCompany(selected?.label === "KHÁC");
    setTimeout(() => {
      setLoadings(false);
    }, 1000);
    if (selected) {
      setOtherEmployerName("");
    }
  };
  const provincesArray = provinces.map((city) => (
    <option key={city.id} value={city.code}>
      {city.name}
    </option>
  ));

  const districtsArray = Object.entries(districts);
  const wardsArray = Object.entries(wards);

  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = Number(value.replace(/[^0-9]/g, ""));
    return numberValue.toLocaleString("vi-VN", {
      style: "currency",
      currency: "VND",
    });
  };
  return (
    <div className="flex flex-col items-center p-4 bg-white">
      {loadings && <LoadingOverlay />}
      <HeaderRegister currentStep={currentStep} />
      <div className="mt-8 w-[100%] bg-green-300 rounded-2xl shadow-lg p-4 custom-shadow">
        <h2 className="text-xl font-bold uppercase text-center">Công việc</h2>

        <div className="mt-5">
          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Tình trạng công việc:
            </label>
            <Select
              value={employerStatus}
              onChange={setEmployerStatus}
              options={employeeStatuss.map((status) => ({
                label: status.name,
                value: status.id,
              }))}
              placeholder="Chọn tình trạng công việc"
            />
            {errors.employerStatus && (
              <p className="text-red-500 text-xs mt-1">
                {errors.employerStatus}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Vị trí công việc:
            </label>
            <Select
              value={selectedJobPosition}
              onChange={setSelectedJobPosition}
              options={jobPositions.map((position) => ({
                label: position.name,
                value: position.code,
              }))}
              placeholder="Chọn vị trí công việc"
              isDisabled={!isEmployerStatusEnabled}
            />
            {errors.selectedJobPosition && (
              <p className="text-red-500 text-xs mt-1">
                {errors.selectedJobPosition}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Tên công ty:
            </label>
            <Select
              name="company"
              value={selectedCompany}
              onChange={handleCompanyChange}
              options={companys.map((company) => ({
                label: company.name,
                value: company.code,
              }))}
              isDisabled={!isEmployerStatusEnabled}
              placeholder="Chọn công ty"
            />
            {errors.selectedCompany && (
              <p className="text-red-500 text-xs mt-1">
                {errors.selectedCompany}
              </p>
            )}
          </div>
          {isOtherCompany && (
            <div className="mb-4">
              <label className="block text-sm font-semibold ml-2 mb-1">
                Tên công ty khác:
              </label>
              <input
                className="h-10 rounded-md text-sm px-2 bg-white w-full"
                placeholder="Nhập tên công ty khác"
                type="text"
                value={otherEmployerName}
                onChange={(e) => setOtherEmployerName(e.target.value)}
              />
              {errors.otherEmployerName && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.otherEmployerName}
                </p>
              )}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Tỉnh/Thành phố:
            </label>
            <select
              value={province}
              onChange={handleProvinceChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn tỉnh/thành phố
              </option>
              {provincesArray}
            </select>

            {errors.province && (
              <p className="text-red-500 text-xs mt-1">{errors.province}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Quận/Huyện:
            </label>
            <select
              value={district}
              onChange={handleDistrictChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn quận/huyện
              </option>
              {districtsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>

            {errors.district && (
              <p className="text-red-500 text-xs mt-1">{errors.district}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Xã/Phường:
            </label>
            <select
              value={ward}
              onChange={(e) => setWard(e.target.value)}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!isEmployerStatusEnabled}
            >
              <option value="" disabled>
                Chọn xã/phường
              </option>
              {wardsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.ward && (
              <p className="text-red-500 text-xs mt-1">{errors.ward}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Địa chỉ chi tiết:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Nhập chi tiết địa chỉ"
              type="text"
              value={detailedAddress}
              onChange={(e) => setDetailedAddress(e.target.value)}
              disabled={!isEmployerStatusEnabled}
            />
            {errors.detailedAddress && (
              <p className="text-red-500 text-xs mt-1">
                {errors.detailedAddress}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Thu nhập hàng tháng:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Thu nhập hàng tháng"
              type="text"
              min="0"
              step="0.01"
              value={formatCurrency(incomeAmount)}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/[^0-9]/g, "");
                setIncomeAmount(rawValue);
              }}
            />
            {errors.incomeAmount && (
              <p className="text-red-500 text-xs mt-1">{errors.incomeAmount}</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6 mb-8">
        <button
          onClick={handleFinalSubmit}
          className="bg-green-500 rounded-lg w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500 "
        >
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
    </div>
  );
};

export default Step4Screen;

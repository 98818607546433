import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import { useUserContext } from "../UserContext";
import { getUpdateToLOS, updateLead, UploadDcument, uploadEKYCLiveness, uploadLiveness, uploadOCRBack, uploadOCRFront } from "../services/apiService";
import Swal from "sweetalert2";

const Face = () => {
  const navigate = useNavigate();
  const [listObjUrls, setListObjUrls] = useState([]);
  const lang = localStorage.getItem('lang');
  const [scanCount, setScanCount] = useState(0);
  const userIdEl = document.getElementById("user-id");
  const trackingConfigEl = document.getElementById("tracking-config");
  const readIdCardLoadingEl = document.getElementById("read-id-card-loading");
  const statusWarmupReadIDCardEl = document.getElementById("status-warmup-read-id-card");
  const livenessLoadingEl = document.getElementById("liveness-loading");
  const startLivenessBtn = document.getElementById("btn-start-liveness");
  const idCardSettingsEl = document.getElementById("id-card-settings");
  const idCardStepsEl = document.getElementById("id-card-steps");
  const { productInfo, setProductInfo } = useUserContext();
  const id = localStorage.getItem("id");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({});
  const { userInfo } = useUserContext();
  const handleSubmit = () => {
    const newErrors = {};
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);
  
      const data = [
        {
          OCR_Image_Back: productInfo.OCR_Image_Back,
          OCR_Image_Front: productInfo.OCR_Image_Front,
          Type: productInfo.Type,
          qr: productInfo.QR_Image,
        },
        {
          szcode: productInfo.szcode,
          liveness: {
            steps: productInfo.liveness.steps,
            capturedFrames: productInfo.liveness.capturedFrames,
            frontalFaces: productInfo.liveness.frontalFaces,
          },
        },
      ];
      const dataStatus = [
        {
          OCR_Image_Back: productInfo.OCR_Image_Back,
          OCR_Image_Front: productInfo.OCR_Image_Front,
          Type: productInfo.Type,
          qr: productInfo.QR_Image,
        },
        {
          szcode: productInfo.szcode,
          liveness: {
            steps: productInfo.liveness.steps,
            capturedFrames: productInfo.liveness.capturedFrames,
            frontalFaces: productInfo.liveness.frontalFaces,
          },
        },
      ];

      uploadEKYCLiveness(id, dataStatus)
        .then((response) => {
          return Promise.all([
            uploadOCRFront(id),
            uploadOCRBack(id),
            uploadLiveness(id),
          ]);
        })
        .then(([ocrFrontResponse, ocrBackResponse, livenessResponse, leadResponse]) => {
        })
        .catch((error) => {
            console.error("Cập nhật thất bại", error);
        })
        .finally(() => setLoadings(false));
    }
  };
  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
     
  const resultLivenessDetectionEl = document.getElementById(
    "result-liveness-detection"
  );
  const myCdnUrlPrefix = "https://vision-vnetwork-cdn.goevo.vn/";
  const customUrls = {
    blazefaceModelUrl: `${myCdnUrlPrefix}models/1.0.0/blazeface/model.json`,
    opencvUrl: `${myCdnUrlPrefix}opencv/4.6.0/cv.js`,
    idCardModelUrl: `${myCdnUrlPrefix}models/1.0.0/id_card/tflite/model-v3.tflite`,
    wechatQRModelsUrl: `${myCdnUrlPrefix}models/1.0.0/webchat_qr/`,
    tfScriptUrls: {
      tfUrl: `${myCdnUrlPrefix}tfjs/3.20.0/tf.min.js`,
      tfBackendWasmUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-wasm.js`,
      tfBackendCpuUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-cpu.js`,
      tfBackendWebGlUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-webgl.js`,
      tfLiteUrl: `${myCdnUrlPrefix}tflite/0.0.1-alpha.8/tflite.min.js`,
      blazefaceScriptUrl: `${myCdnUrlPrefix}blazeface/0.0.7/bf.js`,
    },
  };
  const sdkVersion = "5.22.11";

  useEffect(() => {
    const tv = new window.TVWebSDK.SDK({
      container: document.getElementById("container"),
      lang: lang || "vi",
      assetRoot: myCdnUrlPrefix + "tvweb-sdk/" + sdkVersion + "/assets",
      resourceRoot: myCdnUrlPrefix,
      customUrls: customUrls,
      enableAntiDebug: false,
    });
    window.tv = tv;
    tv.runPreloadEKYCResources();

    const userIdEl = document.getElementById("user-id");
    userIdEl.value = JSON.parse(localStorage.getItem("user-id"));

    const selectModeEl = document.getElementById("select-mode");
    Object.keys(window.TVWebSDK.Constants.Mode).forEach((k) => {
      const option = document.createElement("option");
      option.appendChild(document.createTextNode(k));
      option.setAttribute("value", window.TVWebSDK.Constants.Mode[k]);
      selectModeEl.appendChild(option);
    });

    const idCardSettingsEl = document.getElementById("id-card-settings");
    const idCardStepsEl = document.getElementById("id-card-steps");
    const trackingConfigEl = document.getElementById("tracking-config");

    idCardSettingsEl.value = JSON.stringify(window.TVWebSDK.defaultClientSettings, null, 2);
    idCardStepsEl.value = JSON.stringify(window.TVWebSDK.defaultReadIDCardSteps, null, 2);
    trackingConfigEl.value = JSON.stringify(window.TVWebSDK.TrackingContext.defaultTrackingConfig, null, 2);

    return () => {
      tv.destroyView();
    };
  }, [lang]);

  function onSelectLang() {
    const langEle = document.getElementById("select-lang");
    localStorage.setItem('lang', langEle.value);
    window.location.reload();
  }

  function createObjectURL(blob) {
    const objUrl = URL.createObjectURL(blob);
    setListObjUrls((prev) => [...prev, objUrl]);
    return objUrl;
  }

  function revokeObjectURL() {
    listObjUrls.forEach((item) => URL.revokeObjectURL(item));
    setListObjUrls([]);
  }

  function clearUserId() {
    window.localStorage.removeItem('user-id');
    const userIdEl = document.getElementById("user-id");
    userIdEl.value = '';
  }



  function startLivenessDetection() {
    revokeObjectURL();
    const livenessDetectionMode = document.getElementById("select-mode").value;
    const cameraSelected = document.getElementById("select-camera");
    document.body.style.height = 0;

    if (scanCount === 0) {
      const resultLivenessDetectionElError = document.getElementById("result-liveness-detection-error");
      resultLivenessDetectionElError.innerHTML = "<b style='color: red;'>Vui lòng thử lại!</b>"; 
      setScanCount(1); 
      return; 
    }

    window.tv.livenessDetection({
      apiCheck: !!window.inputAccessKey.value,
      mode: livenessDetectionMode,
      apiCredentials: {
        accessKey: window.inputAccessKey.value,
        secretKey: window.inputSecretKey.value,
        apiUrl: window.inputApiUrl.value,
      },
      
      onLivenessDetectionDone: (result) => {
        handleLivenessDetectionDone(result);
        document.body.style.height = 'auto';
        window.tv.destroyView();
        const resultLivenessDetectionEl = document.getElementById("result-liveness-detection");

        Swal.fire({
          icon: 'success',
          title: 'Xác thực thành công',
          text: 'Đang chuyển hướng...',
       
          customClass: {
            confirmButton: 'btn btn-primary', 
          },
          background: '#fff', 
          timer: 1600, 
        });
        setTimeout(() => {
          navigate("/vpbank-creditcard/contact"); 
        }, 1800);
        setScanCount(scanCount + 1);
    },
    
      captureFrameSettings: {
        enable: true,
        framesIntervalTime: 180,
        framesBatchLength: 15,
      },
      onError: (e) => {
        livenessLoadingEl.style.display = "none";
        startLivenessBtn.disabled = false;
        const resultLivenessDetectionEl = document.getElementById("result-liveness-detection");
        resultLivenessDetectionEl.appendChild(document.createTextNode(`${JSON.stringify(e, null, 2)}`));
      },
      frontCamera: cameraSelected.value === "true",
      onProcessing: () => {
        livenessLoadingEl.style.display = "block";
        startLivenessBtn.disabled = true;
        document.getElementById("result-liveness-detection").innerHTML = "";
      },
      onClose: () => {
        document.body.style.height = 'auto';
        window.tv.destroyView();
      },
    });
  }


  async function handleLivenessDetectionDone(result) {
    const { frontalFaces, apiCheckPassed, steps } = result;
    const frontalFacesBase64 = await Promise.all(
      frontalFaces.map(async (blob) => {
        const base64 = await blobToBase64(blob);
        return base64.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
      })
    );
  
    // Convert each step image to base64 and strip the prefix
    const stepsImagesBase64 = await Promise.all(
      steps.map(async (s) => {
        const base64 = await blobToBase64(s.image.blob);
        return base64.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
      })
    );
    resultLivenessDetectionEl.innerHTML = "";
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      liveness: {
        steps:stepsImagesBase64,
        capturedFrames:result.capturedFrames.map(frame => frame.base64),
        frontalFaces:frontalFacesBase64,
       
      }
   
    }));
    handleSubmit();
    if (!window.inputAccessKey.value) {
      livenessLoadingEl.style.display = "none";
      startLivenessBtn.disabled = false;
      resultLivenessDetectionEl.appendChild(
        document.createTextNode("Random frontal faces detected: ")
      );
      frontalFaces.forEach((blob) => {
        const imgEl = document.createElement("img");
        imgEl.width = "200";
        imgEl.style.marginRight = "5px";
        imgEl.src = createObjectURL(blob);
        resultLivenessDetectionEl.appendChild(imgEl);
      });
      resultLivenessDetectionEl.appendChild(document.createElement("br"));
      resultLivenessDetectionEl.appendChild(document.createElement("br"));
      resultLivenessDetectionEl.appendChild(
        document.createTextNode("Directional faces:")
      );
      steps.forEach((s) => {
        const imgEl = document.createElement("img");
        imgEl.width = "200";
        imgEl.style.marginRight = "5px";
        imgEl.src = createObjectURL(s.image.blob);
        resultLivenessDetectionEl.appendChild(imgEl);
      });
      resultLivenessDetectionEl.appendChild(document.createElement("br"));
      resultLivenessDetectionEl.appendChild(document.createElement("br"));
      resultLivenessDetectionEl.appendChild(
        document.createTextNode("Video:")
      );
    } else {
      livenessLoadingEl.style.display = "none";
      startLivenessBtn.disabled = false;
      resultLivenessDetectionEl.appendChild(
        document.createTextNode(`apiCheckPassed: ${apiCheckPassed}`)
      );
    }
  }

  return (
    <div className="flex flex-col items-center p-5 bg-white ">
        <div id="container"></div>
      <h1 className="text-2xl font-bold mt-2 text-center bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent">
        Xác thực gương mặt
      </h1>

      <p className="text-sm font-normal mt-3 text-gray-600 text-center">
        Vui lòng chụp hình gương mặt để xác thực.
      </p>
      <div style={{display:"none"}} >
         <input id="user-id" type="text" placeholder="User ID" />
         <button onClick={clearUserId}>Clear User ID</button>
       </div>
       <div style={{display:"none"}}>
         <label htmlFor="select-lang">Select Language:</label>
         <select id="select-lang" onChange={onSelectLang}>
           <option value="vi">Tiếng Việt</option>
           <option value="en">English</option>
       </select>
       </div>
       <div style={{display:"none"}}>
         <label htmlFor="select-mode">Select Mode:</label>
         <select id="select-mode"></select>
       </div>
        <div style={{display:"none"}}>
         <label htmlFor="select-camera">Select Camera:</label>
         <select id="select-camera">
           <option value="true">Front Camera</option>
           <option value="false">Back Camera</option>
         </select>
       </div>
      <div className="h-40 w-[96%] bg-gray-300 rounded-3xl mt-5 flex flex-col items-center custom-shadow">
        <img
          id="btn-start-liveness"
          src={require("../assets/face.png")}
          alt="Chụp gương mặt"
          className="w-14 h-[80px] mt-4"
          onClick={startLivenessDetection}
        />
        <span className="text-lg mt-4 font-medium text-slate-600">
          Chụp gương mặt
        </span>
      </div>
      <div className="items-center justify-center mt-6 mb-4">
        <button
          onClick={startLivenessDetection}
          className="bg-green-500 rounded-lg w-56 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500 "
          >
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
      <div id="liveness-loading" style={{paddingTop: "10px", display: "none"}}>
        Api checking
        <div class="dot-windmill"></div>
      </div>
     
       <div id="result-liveness-detection" style={{ display: 'none' }}></div>
       <div id="result-liveness-detection-error"></div>
       <div id="id-card-settings"></div>
       <div id="id-card-steps"></div>
       <div id="tracking-config"></div>
       <div id="read-id-card-loading" ></div>
       <div id="liveness-loading" style={{ display: 'none' }}></div>
    </div>
  );
};

export default Face;

import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [productInfo, setProductInfo] = useState({});

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, productInfo, setProductInfo  }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};

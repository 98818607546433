import React from "react";
import cardImage from "../assets/card.png";
import mailImage from "../assets/mail.png";

const Complete = () => {
  return (
    <div className="flex flex-col items-center p-4 bg-white">
      <h1 className="text-2xl font-bold mt-4 bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent">
        Hoàn Thành Đăng Ký
      </h1>

      <div className="w-[95%] h-[240px] bg-green-300 mt-8 rounded-2xl mb-28 custom-shadow">
        <p className="text-center text-green-800 text-[13px] m-3">
          Chúc mừng bạn đã đăng ký thẻ VPBank thành công thông qua FIMI
        </p>
        <div className="flex-row m-3 w-[76%] flex items-start ml-10">
          <img src={cardImage} className="mr-2 w-14 h-12 mt-3" alt="Card" />
          <p className="text-base flex-wrap font-medium ml-2">
            Thẻ vật lý sẽ được gửi đến{" "}
            <span className="text-green-600">VPBank chi nhánh......</span> trong
            vòng <span className="text-green-600">7 ngày </span>
            làm việc.
          </p>
        </div>
        <div className="flex-row m-3 w-[76%] flex items-start ml-10">
          <img src={mailImage} className="mr-2 w-14 h-12" alt="Mail" />
          <p className="text-base flex-wrap font-medium ml-2">
            Hợp đồng thẻ sẽ được gửi đến địa chỉ email.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Complete;

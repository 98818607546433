import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import './style.css';
import Footer from './components/Footer.js';
import Copyright from './components/Copyright.js';
import Step1Screen from './pages/Step1Screen.js';
import Step2Screen from './pages/Step2Screen.js';
import Step3Screen from './pages/Step3Screen.js';
import Step4Screen from './pages/Step4Screen.js';
import CheckData from './pages/CheckData.js';
import ChooseProduct from './pages/ChooseProduct.js';
import VerifyInfo from './pages/VerifyInfo.js';
import Face from './pages/Face.js';
import Contact from './pages/Contact.js';
import Complete from './pages/Complete.js';
import ScrollToTop from './ScrollToTop.js';
import { UserProvider } from './UserContext.js';
import OTPScreen from './pages/OTPScreen.js';
import PageStart from './pages/PageStart.js';

function App() {
  
  return (
    <UserProvider>
    <Router>
    <div class="relative flex h-full w-full items-center justify-center bg-background">
    <div
			class="container h-[calc(100vh-10px)] grid grid-rows-[auto_1fr_auto] w-[430px] overflow-y-auto rounded-lg border-foreground/30 bg-background sm:border">
        <ScrollToTop/>
      <Header />
        <Routes>
          <Route path="/" element={<PageStart />} />
          <Route path="/vpbank-creditcard" element={<Step1Screen />} />
          <Route path="/vpbank-creditcard/step2Screen" element={<Step2Screen />} />
          <Route path="/vpbank-creditcard/step3Screen" element={<Step3Screen />} />
          <Route path="/vpbank-creditcard/step4Screen" element={<Step4Screen />} />
          <Route path="/vpbank-creditcard/checkData" element={<CheckData />} />
          <Route path="/vpbank-creditcard/chooseProduct" element={<ChooseProduct />} />
          <Route path="/vpbank-creditcard/verifyInfo" element={<VerifyInfo />} />
          <Route path="/vpbank-creditcard/face" element={<Face />} />
          <Route path="/vpbank-creditcard/contact" element={<Contact />} />
          <Route path="/vpbank-creditcard/complete" element={<Complete />} />
          <Route path="/vpbank-creditcard/OTPScreen" element={<OTPScreen />} />

        </Routes>
      <Footer />
      <Copyright />
      </div>
      </div>
    </Router>
    </UserProvider>
  );
}

export default App;

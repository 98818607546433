import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";

const ChooseProduct = () => {
  const navigate = useNavigate();
  const { productInfo, setProductInfo } = useUserContext();
  const [showAllCards, setShowAllCards] = useState(false);

  const handleSelectCard = (szcode) => {
    const dataToSubmit = {
      ...productInfo,
      szcode: szcode,
    };
    setProductInfo(dataToSubmit);
    navigate("/vpbank-creditcard/verifyInfo"); 
  };

  return (
    <div className="flex flex-col items-center p-5 bg-white h-full">
      <h1 className="bg-gradient-to-r from-green-700 to-green-500 bg-clip-text text-transparent text-2xl font-bold mt-6 text-center">
        Lựa chọn sản phẩm
      </h1>
      <p className="text-sm font-normal mt-3 text-gray-400 text-center">
        Chúc mừng bạn đã đủ điều kiện mở thẻ VPBank
      </p>
      <h2 className="text-base font-medium mt-4 text-black text-center">
        Hạn mức thẻ{" "}
        <span className="text-green-600 text-xl">15 triệu đồng</span>
      </h2>

      <div className="mt-8 flex flex-col items-center">
        {/* Card 1 */}
        <div className="flex flex-col items-center mb-6">
          <img
            src={require("../assets/ribbon1.png")}
            alt="Ribbon 1"
            className="w-80 h-9"
          />
          <img
            src={require("../assets/example-card1.png")}
            alt="Card 1"
            className="w-64 h-40 mt-5"
          />
          <h3 className="bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent text-lg font-bold mt-4 text-center">
            VPBank Shopper Platinum
          </h3>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">
              Tận hưởng mua sắm tại Shopee, NowFood với ưu đãi hoàn tiền 6% và mã freeship quanh năm.
            </p>
          </div>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">Miễn phí thường niên.</p>
          </div>
          <div className="items-center justify-center mt-5">
            <button
              onClick={() => handleSelectCard("VSSP")}
              className="bg-green-500 rounded-lg w-64 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500"
            >
              <span className="text-white text-base font-semibold">Chọn thẻ</span>
            </button>
          </div>
        </div>

        {/* Card 2 */}
        <div className="flex flex-col items-center mb-6 mt-6">
          <img
            src={require("../assets/ribbon2.png")}
            alt="Ribbon 2"
            className="w-80 h-9"
          />
          <img
            src={require("../assets/example-card2.png")}
            alt="Card 2"
            className="w-64 h-40 mt-5"
          />
          <h3 className="bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent text-lg font-bold mt-4 text-center">
            VPBank MC2 Master Card
          </h3>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">
              Tận hưởng mua sắm tại Shopee, NowFood với ưu đãi hoàn tiền 6% và mã freeship quanh năm.
            </p>
          </div>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">Miễn phí thường niên.</p>
          </div>
          <div className="items-center justify-center mt-5">
            <button
              onClick={() => handleSelectCard("MCON")}
              className="bg-green-500 rounded-lg w-64 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500"
            >
              <span className="text-white text-base font-semibold">Chọn thẻ</span>
            </button>
          </div>
        </div>

        {/* Card 3 */}
        <div className="flex flex-col items-center mb-6 mt-4">
          <img
            src={require("../assets/travel 1.png")}
            alt="Card 3"
            className="w-64 h-40 mt-5"
          />
          <h3 className="bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent text-lg font-bold mt-4 text-center">
            VPBank Visa Signature Travel Miles
          </h3>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">Tích lũy dặm bay vượt trội từ mọi giao dịch.</p>
          </div>
          <div className="flex flex-row w-[77%] mt-2 ml-1">
            <img
              src={require("../assets/icon/vp.png")}
              alt="Icon"
              className="w-5 h-5 mr-1"
            />
            <p className="text-xs font-normal text-gray-400">Miễn phí thường niên.</p>
          </div>
          <div className="items-center justify-center mt-5">
            <button
              onClick={() => handleSelectCard("VSTC")}
              className="bg-green-500 rounded-lg w-64 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500"
            >
              <span className="text-white text-base font-semibold">Chọn thẻ</span>
            </button>
          </div>
        </div>
      </div>

      <div className="items-center justify-center">
        <button
          onClick={() => setShowAllCards(!showAllCards)}
          className="bg-white border-2 border-green-500 rounded-xl w-64 h-12 items-center justify-center mb-5 transition-transform duration-300 hover:scale-105 hover:bg-white"
        >
          <span className="text-green-500 text-base font-semibold">
            {showAllCards ? "Ẩn thẻ" : "Xem toàn bộ thẻ"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ChooseProduct;

import React from 'react';

const Footer = () => {
  return (
    <div className="h-36 bg-gray-300 flex justify-center items-center relative ">
      <img
        src={require("../assets/logo/vpbank-brand.png")}
        alt="VPBank Logo"
        className="absolute top-2 w-36 h-12"
    />
      <div className="absolute top-[67px] flex flex-col items-center">
        <p className="text-black text-xs mt-0">89 Láng Hạ - Đống Đa - Hà Nội</p>
        <p className="text-black text-xs mt-2">Hotline: 1900 54 54 15</p>
        <p className="text-black text-xs mt-2">Email: kncn.vpbank.com.vn</p>
      </div>
    </div>
  );
};

export default Footer;
